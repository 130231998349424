import React, { memo, useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { GetAllTicket} from "../../redux/actions/maintainance";
import Maintenance from "./index";
import moment from "moment";
import WrapperComponent from "../wrapperComponent";
import { useTranslation } from "react-i18next";
import ReactPaginate from 'react-paginate';
import { useHistory  } from 'react-router-dom'

const MaintenanceList = memo(({ tickets = [], GetAllTicket, Leases, isMaintenanceBlocked }) => {
  const [modalDialog, setModalDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [edit, setEdit] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [ticketList, setTicketList] = useState([]);
  const [cross,setCross] = useState(true);
  const { t } = useTranslation();
  const [modalError, setModalError] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (tickets) {
      sorter(tickets);
    }
  }, [tickets]);
  
  useEffect(() => {
    if (!tickets.length && Leases && Leases.length) {
      GetAllTicket(onSuccess,page+1);
      setLoading(true);
    }

  }, []);

  useEffect(()=>{
    console.log({isMaintenanceBlocked})
    if(isMaintenanceBlocked === "1"){
      history.push("/dashboard");
    }
  }, [])

  const statusTranslation = (text) => {
    return text === "New" ? 
      (t('maintenance-status.new')) : (text === "Verified") ?
      (t('maintenance-status.verified')) : (text === "Verified by Tenant") ?
      (t('maintenance-status.verified-tenant')) : (text === "Closed") ?
      (t('maintenance-status.closed')) : (text === "Scheduled") ?
      (t('maintenance-status.scheduled')) : (text === "Re-Opened by Tenant") ?
      (t('maintenance-status.re-opened')) : (text === "Scheduled - Confirmed") ?
      (t('maintenance-status.scheduled-confirmed')) : (text === "Canceled") ?
      (t('maintenance-status.canceled')) : text
  }

  const categoryTranslation = (text) => {
    return text === "Cleaning" ? 
      (t('maintenance-category.cleaning')) : (text === "Plumbing") ?
      (t('maintenance-category.plumbing')) : (text === "Extermination") ?
      (t('maintenance-category.extermination')) : (text === "Electricity") ?
      (t('maintenance-category.electricity')) : (text === "Scheduled") ?
      (t('maintenance-category.scheduled')) : (text === "Carpentry") ?
      (t('maintenance-category.carpentry')) : (text === "Landscaping") ?
      (t('maintenance-category.landscaping')) : text
  }

  // callback function set loading to false
  const onSuccess = () => {
    setLoading(false);
  };

  // sorter function
  const sorter = (data) => {
    // let b = moment().format("MM/DD/YYYY");
    // let list = data.filter((item) => {
    //   let a = moment(item["Lease_MAIN::Date_Max"], "MM/DD/YYYY");
    //   return (
    //     Math.abs(a.diff(b, "days")) < 22 ||
    //     [ "new", "verified", "closed", "scheduled", "scheduled - confirmed", "re-opened by tenant" ]
    //     .includes(item["Lease_MAIN::Status"].toLowerCase())
    //   );
    // });
    setTicketList(data?.data);
    if(data?.data?.length)
    {
      setPageCount(Math.ceil(data?.totalCount / 20))

    }
  };


  const closeModal = (bool) => {
    setEdit(false);
    setModalDialog(false);
    setSelectedItem({});
    setHeaderTitle("");
    setCross(true);
  };

  const toggleModal = (e) => {
    e && e.stopPropagation();
    setModalDialog(!modalDialog);
    setCross(true)
  };

  const handlePageClick = (e) =>{
    setPage(e.selected);
    if (Leases && Leases.length){
      GetAllTicket(onSuccess,e.selected+1);
    }
  }

  const renderExterminationErrorModal = () => {
    return (
      <Modal size="md" centered isOpen={modalError} toggle={()=> setModalError('')}>
         <ModalBody>
            <p style={{fontSize: 16}}>{modalError}</p>
         </ModalBody>
         <ModalFooter>
          <button className="btn btn-primary" onClick={()=> setModalError('')}>Close</button>
         </ModalFooter>
      </Modal>
    )
  }


  return (
    <div>
      {renderExterminationErrorModal()}
      <Modal isOpen={modalDialog} toggle={() => toggleModal()}>
        <ModalHeader toggle={() => closeModal()}
        close = {<button className={(edit && cross )? "close" : "close d-none"} onClick={toggle => closeModal()}>x</button>}
        >
          {headerTitle || "Ania Management"}{" "}
          {(headerTitle == "Maintenance Ticket" &&
            selectedItem["Lease_MAIN::zzIDFriendly"]) ||
            ""}
        </ModalHeader>
        <ModalBody>
          <Maintenance
            item={selectedItem}
            edit={edit}
            closeModal={closeModal}
            setHeaderTitle={setHeaderTitle}
            setCross={setCross}
            setModalError={setModalError}
          />
        </ModalBody>
      </Modal>
      <Panel>
        <PanelHeader noButton={true}>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="stats-title text-uppercase text-white font-weight-bold">
                {t('card-title.maintenance')}
              </div>
              <div className="stats-desc">
                {t('card-subtitle.maintenance')}
              </div>
            </div>
            {
              Leases && Leases.length>0 && 
              <button
              className="btn btn-warning btn-icon"
              onClick={(e) => {
                if (!loading) {
                  setHeaderTitle(t('modal.maintenance.header-add-maintenance'));
                  setEdit(false);
                  setSelectedItem({});
                  toggleModal(e);
                }
              }}
            >
              <i
                className={!loading ? "fa fa-plus" : "fas fa-spinner fa-pulse"}
              ></i>
            </button>
            }
           
          </div>
        </PanelHeader>
        <PanelBody>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-nowrap">{t('card-table.maintenance.ticketno')}</th>
                  <th className="text-nowrap">{t('card-table.maintenance.date')}</th>
                  <th className="text-nowrap">{t('card-table.maintenance.category')}</th>
                  <th className="text-nowrap">{t('card-table.maintenance.description')}</th>
                  <th className="text-nowrap">{t('card-table.maintenance.status')}</th>
                  <th className="text-nowrap"></th>
                </tr>
              </thead>
              <tbody>
                {ticketList &&
                  ticketList.map((item, i) => (
                    <tr
                      key={item?._id}
                      className="hover-pointer"
                      onClick={(e) => {
                        setEdit(true);
                        setSelectedItem(item);
                        setHeaderTitle("Maintenance Ticket");
                        toggleModal();
                      }}
                    >
                      <td className="text-capitalize">
                        {item?.fieldData?.zzIDFriendly || "-"}
                      </td>
                      <td>{item?.fieldData?.zzCreatedDate || "-"}</td>
                      <td className="text-capitalize">
                        {categoryTranslation(item?.fieldData?.Type) || "-"}
                      </td>
                      <td className="text-sentence">
                        {item?.fieldData?.Description_Web || "-"}
                      </td>
                      <td>{statusTranslation(item?.fieldData?.Status) || "-"}</td>
                      <td className="text-center">
                        <i className="fa fa-chevron-right" />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </PanelBody>
      </Panel>
      {
        pageCount ?
        <ReactPaginate
          previousLabel={<i className="fas fa-angle-double-left" />}
          nextLabel={<i className="fas fa-angle-double-right" />}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
        /> : null
      }
    </div>
  );
});

const mapStateToProps = ({ MAINTENANCE_REDUCER, USER_REDUCER }) => {
  return {
    tickets: MAINTENANCE_REDUCER.maintainence_tickets,
    Leases: USER_REDUCER.AllLeases,
    isMaintenanceBlocked: USER_REDUCER?.userData?.leaseDetails && USER_REDUCER.userData?.leaseDetails['Lease_PROP::isMaintenanceBlcked']
  };

};

const MaintenanceWrapper = (props) => (
  <WrapperComponent>
    <MaintenanceList {...props} />
  </WrapperComponent>
);

export default connect(mapStateToProps, { GetAllTicket })(
  MaintenanceWrapper
);
