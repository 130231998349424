import Api from "../api/api";
import { Notify } from "../../helpers/notification";
import {getDashboardData} from './user'

export const TICKET_DETAILS = "TICKET_DETAILS";
export const GET_SURVEY = "GET_SURVEY";
export const ALL_TICKET = "ALL_TICKET";
export const MAINTAINANCE_TICKET_DATA = "MAINTAINANCE_TICKET_DATA";

export const GetTicket = (body, cb) => (dispatch) => {
  Api.post(`api/maintainance/getTicket`, body)
    .then((response) => {
      cb && cb(response.data.data);
      dispatch({ type: TICKET_DETAILS, payload: response.data.data });
    })
    .catch((err) => {
      console.log(err)
      cb && cb(false);
    })
    .catch(() => {
      Notify('danger', "Something Bad Happened :(");
    });
};

export const GetMaintainanceTicketData = (body, cb) => (dispatch) => {
  Api.post(`api/maintainance/fetch/this-week-data`, body)
    .then((response) => {
      cb && cb(response.data.data);
      dispatch({ type: MAINTAINANCE_TICKET_DATA, payload: response.data.data });
    })
    .catch((err) => {
      console.log(err)
      cb && cb(false);
    })
    .catch(() => {
      Notify('danger', "Something Bad Happened :(");
    });
};

export const GetTicketUnsecuredAction = (id, cb) => (dispatch) => {
  Api.get(`api/maintainance/getTicket-unsecured/${id}`)
    .then((response) => {
      cb && cb(response.data.data);
      dispatch({ type: TICKET_DETAILS, payload: response.data.data });
    })
    .catch((err) => {
      console.log(err)
      cb && cb(false);
    })
    .catch(() => {
      Notify('danger', "Something Bad Happened :(");
    });
};

export const GetTicketByZZIDUnsecuredAction = (id, cb) => (dispatch) => {
  Api.get(`api/maintainance/getTicketByZZID-unsecured/${id}`)
    .then((response) => {
      cb && cb(response.data.data);
      dispatch({ type: TICKET_DETAILS, payload: response.data.data });
    })
    .catch((err) => {
      console.log(err)
      cb && cb(false);
    })
    .catch(() => {
      Notify('danger', "Something Bad Happened :(");
    });
};

export const GetAllTicket = (cb,page) => (dispatch) => {
  Api.get(`api/maintainance/getAllTicket/`+page)
    .then((response) => {
      cb && cb(response.data.data);
      dispatch({ type: ALL_TICKET, payload: response.data.data });
    })
    .catch((err) => {
      cb && cb(false);
    })
    .catch(() => {
      Notify('danger', "Something Bad Happened :(");
    });
};

export const AddTicket = (payload, cb, errCB) => (dispatch) => {
  Api.post("api/maintainance/addTicket", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => {
      cb && cb(response.data.data);
      dispatch(GetAllTicket())
    })
    .catch((err) => {
      errCB && errCB(err)
      // Notify('danger', "Something Bad Happened :(");
    });
};


export const EditUpdateTicket = (payload, cb) => (dispatch) => {
  Api.post(`api/maintainance/editTicket`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => {
      cb && cb(response?.data?.data);
      dispatch(GetAllTicket(()=> {}, 1))
    })
    .catch((err) => {
      cb && cb(false)
      // Notify('danger', err.response.data.message);
    })
    .catch(() => {
      Notify('danger', "Something Bad Happened :(");
    });
};

export const EditUpdateTicketAsync = async (payload) => {
  try {
    const response = await Api.post(`api/maintainance/editTicket`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    })
    return response?.data?.data
  } catch (error) {
    Notify('danger', "Something Bad Happened :(");
    console.error(error);
  }

};

export const GetSurvey = (id, cb) => (dispatch) => {
  Api.get(`api/maintainance/getSurvey/${id}`)
    .then((response) => {
      cb && cb(true);
      dispatch({ type: GET_SURVEY, payload: response.data.data})
    })
    .catch((err) => {
      cb && cb(false)
      // Notify('danger', err.response.data.message);
    })
    .catch(() => {
      cb && cb(false)
      Notify('danger', "Something Bad Happened :(");
    });
};

export const UpdateSurvey = (payload, cb) => (dispatch) => {
  Api.post(`api/maintainance/updateSurvey`, payload)
    .then((response) => {
      cb && cb()
    })
    .catch((err) => {
      // Notify('danger', err.response.data.message);
      cb && cb()
    })
    .catch(() => {
      Notify('danger', "Something Bad Happened :(");
      cb && cb()
    });
};

export const addSurveyAction = (payload, cb) => (dispatch) => {
  Api.post(`api/maintainance/add-survey`, payload)
    .then((response) => {
      cb && cb()
    })
    .catch((error) => {
      Notify('danger', "Something Bad Happened :(");
      console.log(error);
      cb && cb()
    });
};

export const GetMaintenanceTypes = () => {
  return Api.get("api/maintainance/getMaintenanceTypes");
};


