import React, { memo, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  GetSurvey,
  UpdateSurvey,
  EditUpdateTicket,
  addSurveyAction,
} from "../../redux/actions/maintainance";
import { connect, useDispatch } from "react-redux";
import { Notify } from "../../helpers/notification";
import * as _ from "lodash";
import { useTranslation } from 'react-i18next';

const staticQuestions = {
  "understand": "The technician understood my request",
  "knowledgable": "The technician was knowledgeable",
  "professional": "The technician acted in a professional manor",
  "satisfied": "Overall, I am very satisfied with the technician",
  "responseTime": "Ania Management responded in a timely manner.",
};

const Survey = memo(({ GetSurvey, UpdateSurvey, EditUpdateTicket, user, data = {}, closeModal, setIsSchedule }) => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [answered, setAnswered] = useState(false);
    const [note, setNote] = useState("");
    const dispatch = useDispatch();
    const [survey, setSurvey] = useState({
      formData: {
        technician: {
          sectionTitle: "The technician...",
          questions: [
            {
              question: t('modal.maintenance.survey.q1'),
              order: 1,
              answer: "",
              name: "understand",
            },
            {
              question: t('modal.maintenance.survey.q2'),
              order: 2,
              answer: "",
              name: "knowledgable",
            },
            {
              question: t('modal.maintenance.survey.q3'),
              order: 3,
              answer: "",
              name: "professional",
            },
            {
              question: t('modal.maintenance.survey.q4'),
              order: 4,
              answer: "",
              name: "satisfied",
            },
          ],
        },
        florio: {
          sectionTitle: "florio",
          questions: [
            {
              question: t('modal.maintenance.survey.q5'),
              order: 1,
              answer: "",
              name: "responseTime",
            },
          ],
        },
      },
    });

    useEffect(() => {
      data.type === "verified" && GetSurvey(data.recordId);
    }, []);

    // function to handle user's response and update the state
    const handleAnswer = (question, index, value) => {
      !answered && setAnswered(true);
      let obj = _.cloneDeep(survey);
      let obj1 = {
        formData: {
          ...obj.formData,
          [question]: {
            ...obj.formData[question],
            questions: obj.formData[question].questions.map((item, i) =>
              i === index ? { ...item, answer: value } : item
            ),
          },
        },
      };
      let sorted = {
        formData: {
          technician: obj1.formData.technician,
          florio: obj1.formData.florio,
        },
      };
      setSurvey(sorted);
    };

    // callback function which runs on submit survey
    const onSuccess = () => {
      if (note) {
        setLoading(false);
        setIsSchedule(true)
      } else {
        closeModal();
        setLoading(false);
      }
    };

    const handleSubmit = () => {
      if (data.type === "Verified by Tenant") {
        if (answered) {
          let payload = {
            zzId_MAIN: data.zzId_MAIN,
            result: JSON.stringify({formData: {
              "technician": {
                sectionTitle: "The technician...",
                questions: survey.formData["technician"].questions.map(que => ({
                  ...que,
                  question: staticQuestions[que.name]
                }))
              },
              "florio": {
                sectionTitle: "florio",
                questions: survey.formData["florio"].questions.map(que => ({
                  ...que,
                  question: staticQuestions[que.name]
                }))
              }
            }}),
            zzModifiedByWeb: user.Email_01,
          };
          dispatch(addSurveyAction(payload, onSuccess));
          setLoading(true);
        } else {
          Notify("info", "Atleast select one answer");
        }
      } else {
        const formData = new FormData();
        formData.append("note", note);
        // formData.append("zzId_MAIN", data.zzId_MAIN);
        formData.append("zzCreatedByWeb", user.Email_01);
        formData.append("zzModifiedByWeb", user.Email_01);
        formData.append("_id", data?._id)
        EditUpdateTicket(formData, onSuccess);
        setLoading(true);
      }
    };

    return (
      <section>
        <div className="survey-container">
          {data && data.type == "Verified by Tenant" ? (
            survey &&
            survey.formData &&
            ["technician", "florio"].map((item) => (
              <>
                {survey.formData[item].questions.map((que, num) => (
                  <div key={num}>
                    <h5>{que.question}</h5>
                    <div className="d-flex justify-content-around">
                      {[
                        { mark: 1, subTitle: t('modal.maintenance.survey.disagree') },
                        { mark: 2 },
                        { mark: 3 },
                        { mark: 4 },
                        { mark: 5, subTitle: t('modal.maintenance.survey.agree') },
                      ].map((star, i) => (
                        <span className="text-center text-muted">
                          <i
                            key={i}
                            className={`${
                              star.mark <= que.answer ? "fas" : "far"
                            } fa-lg fa-fw m-r-10 fa-star fa-2x m-2 hover-pointer`}
                            fontSize="large"
                            onClick={(e) => handleAnswer(item, num, star.mark)}
                          />
                          <p>{star.subTitle}</p>
                        </span>
                      ))}
                    </div>
                  </div>
                  ))}
              </>
            ))
          ) : (
            <textarea
              className="form-control"
              placeholder="Write a Note..."
              rows="4"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            ></textarea>
          )}
          <button
            disabled={loading}
            className="btn btn-primary btn-block m-t-5"
            onClick={(e) => {
              handleSubmit();
            }}
          >
            {!loading ? `${t('modal.maintenance.submit')}` : <i className="fas fa-spinner fa-pulse" />}
          </button>
        </div>
      </section>
    );
  }
);

const mapStatetoProps = ({ USER_REDUCER }) => {
  return { user: USER_REDUCER.userData };
};

export default connect(mapStatetoProps, {
  GetSurvey,
  UpdateSurvey,
  EditUpdateTicket,
})(withRouter(Survey));
