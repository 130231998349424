import React, { useEffect, useState, forwardRef } from "react";
import { withRouter } from "react-router-dom";
import { Validation } from "../../helpers/validations";
import { connect } from "react-redux";
import _ from "lodash";
import {
  AddTicket,
  GetTicket,
  EditUpdateTicket,
  GetMaintenanceTypes
} from "../../redux/actions/maintainance";
import { TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalBody } from "reactstrap";
import Dropzone from "react-dropzone";
import classnames from "classnames";
import moment from "moment";
import Survey from "./survey";
import heic2any from "heic2any";
import WrapperComponent from "../wrapperComponent";
import MaintenanceSchedule from "./MaintenanceSchedule";
// import firebase from "../../services/firebase";
import { useTranslation } from "react-i18next";
import { getDashboardData } from "../../redux/actions/user";

const Maintainence = forwardRef(
  (
    {
      item,
      AddTicket,
      GetTicket,
      EditUpdateTicket,
      user,
      edit,
      closeModal,
      setHeaderTitle,
      action, //status of deep link
      setCross,
      resfreshData,
      setRefreshData,
      getDashboardData,
      setModalError
    },
    myRef
  ) => {
    const currentDate = new Date();
    const nextDate = new Date(new Date().setDate(new Date().getDate() + 1));
    const [data, setData] = useState({});
    const [imgdata, setImgData] = useState([]);
    const [images, setImages] = useState([]);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [statusChange, setStatusChange] = useState(false);
    const [statusType, setStatusType] = useState("");
    const [note, setNote] = useState("");
    const [activeTab, setActiveTab] = useState("1");
    const [modalDialog, setModalDialog] = useState(false);
    const [beginUpload, setBeginUpload] = useState(false);
    const [heicImages, setHeicImages] = useState([]);
    const [isSchedule, setIsSchedule] = useState(false);
    const [date, setDate] = useState({
      date1: "",
      date2: "",
      date3: "",
    });
    const [timeSlot, setTimeSlot] = useState({
      tSlot1: "",
      tSlot2: "",
      tSlot3: "",
    });
    const [categoryList, setCategoryList] = useState({});
    const [statusText, setStatusText] = useState("");
    const [newTicketData, setNewTicketData] = useState();
    
    const { t } = useTranslation();

    const whereList = ["Bedroom", "Bathroom", "Kitchen", "Living Room", "Hallway", "Whole Unit"];

    useEffect(() => {
      addBusinessDays();
    }, []);

    // retrieving category and sub-category types from firestore in firebase
    useEffect(() => {
      GetMaintenanceTypes().then((response) => {
          setCategoryList(response.data.data);
      }).catch(error => console.log(error));
    }, []);

    // Logic for initializing scheduling dates
    const addBusinessDays = () => {
      
      // Condition for extracting a 24 hours format time ----> 
      let a = currentDate.toLocaleTimeString('en-US',{ hour12: false, hour: '2-digit', minute: '2-digit' }).replace(/([\d]+:[\d]{2})(:[\d]{2})(. *)/, "$1$3");
      a = a.replace(":","");
      let dateConsidered = new Date();
      if ((moment(currentDate).format("dddd") === "Saturday") && (parseInt(a) < 1300)) {
        dateConsidered = currentDate
      } else if (moment(currentDate).format("dddd") !== "Saturday")  {
        if (parseInt(a) < 1500) {
          dateConsidered = currentDate
       } else 
          dateConsidered = nextDate
      }

      if (moment(dateConsidered).format("dddd") === "Sunday") {
        setDate({
          date1: new Date(dateConsidered.getTime() + 86400000), // currentDate + 2
          date2: new Date(dateConsidered.getTime() + 172800000), // currentDate + 3
          date3: new Date(dateConsidered.getTime() + 259200000), // currentDate + 4
        });
      } else if (
        moment(new Date(dateConsidered.getTime() + 86400000)).format("dddd") ===
        "Sunday"
      ) {
        setDate({
          date1: dateConsidered, //currentDate + 1
          date2: new Date(dateConsidered.getTime() + 172800000), //currentDate + 3
          date3: new Date(dateConsidered.getTime() + 259200000), //currentDate + 4
        });
      } else if (
        moment(new Date(dateConsidered.getTime() + 172800000)).format("dddd") ===
        "Sunday"
      ) {
        setDate({
          date1: dateConsidered, //currentDate + 1
          date2: new Date(dateConsidered.getTime() + 86400000), //currentDate + 2
          date3: new Date(dateConsidered.getTime() + 259200000), //currentDate + 4
        });
      } else {
        setDate({
          date1: dateConsidered, //currentDate + 1
          date2: new Date(dateConsidered.getTime() + 86400000), //currentDate + 2
          date3: new Date(dateConsidered.getTime() + 172800000), //currentDate + 3
        });
      }
    };

    const resetForm = () => {
      setNote("");
      setLoading(false);
      setImgData([]);
      setImages([]);
      setHeicImages([]);
      setBeginUpload(false);
    };

    const formatData = (data) => {
      resetForm();
      if (data) {
        if (statusChange) {
          setHeaderTitle(
            statusType === "Verified by Tenant"
              ? `${t('modal.maintenance.let-us-know')}`
              : `${t('modal.maintenance.still-need-to-do')}`
          );
          setModalDialog(true);
        }
        setStatusChange(false);
        let obj = {};
        obj._id = data._id
        obj.category = data.fieldData?.Type;
        obj.subCategory = data.fieldData?.SubType;
        obj.SubTypeWhere = data.fieldData?.SubTypeWhere;
        obj.description = data.fieldData?.Description_Web;
        obj.notes =  data.portalData?.notesCreate;
        obj.images = data.portalData?.Maintenance_NOTE_Images;
        obj.zzId_MAIN = data.fieldData?.zzID;
        obj.recordId = data.recordId;
        obj.zzIDFriendly = data.fieldData?.zzIDFriendly;
        obj.older90 = moment().diff(data.fieldData?.Date_Max, 'days') > 90;
        obj.status =
          data.fieldData?.Status?.toLowerCase() === "verified" ||
          data.fieldData?.Status?.toLowerCase() === "closed";
        obj.Status = data.fieldData?.Status
        obj.descriptionPresent = obj.description ? true : false;
        obj.subCategoryPresent = obj.subCategory ? true : false;
        obj.SubTypeWherePresent = obj.SubTypeWhere ? true : false;
        obj.isVerifiedByTenant = data.fieldData?.isVerifiedByTenant;
        obj.isSurveyDone = data.fieldData["Maintenance_SRVY::zzk_1"];
        setStatusType(data.fieldData?.Status);
        if (
          data.fieldData?.Status === "New" ||
          data.fieldData?.Status === "Re-Opened by Tenant"
        ) {
          if (
            data.fieldData?.web_Schedule01 &&
            data.fieldData?.web_Schedule02 &&
            data.fieldData?.web_Schedule03
          ) {
            setDate({
              date1: new Date(data.fieldData?.web_Schedule01?.substring(0, 10)),
              date2: new Date(data.fieldData?.web_Schedule02?.substring(0, 10)),
              date3: new Date(data.fieldData?.web_Schedule03?.substring(0, 10)),
            });
            setTimeSlot({
              tSlot1: data.fieldData?.web_Schedule01?.substring(11),
              tSlot2: data.fieldData?.web_Schedule02?.substring(11),
              tSlot3: data.fieldData?.web_Schedule03?.substring(11),
            });
          } else {
            addBusinessDays();
          }
        }
        setData(obj);
      }
    };

    // function to fetch Maintenance ticket
    const getTicketdata = (bool) => {
      const getId = () => {
        return {
          zzID: item?.['Lease_MAIN::zzID'] || item?.['zzID'] || newTicketData?.[0]?.['Lease_MAIN::zzID'] || newTicketData?.[0]?.['zzID'] || "" ,
          _id: item?._id || item?.mongo_id || newTicketData?.[0]?._id || newTicketData?.[0]?.mongo_id || ""};
      };
    
      if (bool) {
        const executeGetTicket = () => {
          GetTicket(getId(), formatData);
          if (isSchedule) closeModal();
        };
    
        if (images.length || heicImages.length) {
          setTimeout(executeGetTicket, 3000);
        } else {
          executeGetTicket();
        }
      } else {
        setStatusChange(false);
        return setLoading(false);
      }
    
      if (!loading) setLoading(true);
    };
    

    useEffect(() => {
      if (edit) {
        getTicketdata(true);
        setData({ descriptionPresent: true, subCategoryPresent: true });
      }
      if (action) {
        setData({
          zzId_MAIN: item["Lease_MAIN::zzID"],
          status: action,
          recordId: item.recordId,
        });
        setStatusChange(action);
      }
    }, []);

    useEffect(() => {
      statusText === "Re-Opened by Tenant" && handleUpdateTicket();
      statusText === "Verified by Tenant" && handleUpdateTicket();
    }, [statusText]);

    useEffect(() => {
      if (edit && beginUpload) {
        handleUpdateTicket();
      }
    }, [beginUpload]);

    // function which handles both heic & jpg, jpeg etc images
    const handleImage = (imageList) => {
      let arr = [];
      let imgArr = [];
      let heicArr = [];
      let count = 0;
      let maxlen = imageList.length >= 2 ? 2 : imageList.length;
      for (let i = 0; i < maxlen; i++) {
        let imgData = imageList[i];
        if (imgData.name.split(".")[1].toLowerCase() === "heic") {
          heic2any({ blob: imgData, toType: "image/jpeg", quality: 1 }).then(
            (result) => {
              heicArr.push(result);
              let reader = new FileReader();
              reader.onloadend = () => {
                arr.push({ imagePreviewUrl: URL.createObjectURL(result) });
                count += 1;
                if (count == maxlen) {
                  setImgData(arr);
                  setImages(imgArr);
                  setHeicImages(heicArr);
                  setBeginUpload(true);
                }
              };
              reader.readAsDataURL(imgData);
            }
          );
        } else {
          if (imgData && imgData.type.split("/")[0] === "image") {
            imgArr.push(imgData);
            let reader = new FileReader();
            reader.onloadend = () => {
              arr.push({ imagePreviewUrl: URL.createObjectURL(imgData) });
              count += 1;
              if (count == maxlen) {
                setImgData(arr);
                setImages(imgArr);
                setHeicImages(heicArr);
                setBeginUpload(true);
              }
            };
            reader.readAsDataURL(imgData);
          }
        }
      }
    };

    // validator function
    const handleValidation = ({ category, subCategory, SubTypeWhere, description }) => {
      if (Validation.empty(category)) {
        setError({ ...error, category: t('modal.maintenance.validation.category') });
        return false;
      } else if (Validation.empty(subCategory)) {
        setError({ ...error, subCategory: t('modal.maintenance.validation.sub-category') });
        return false;
      } else if (Validation.empty(SubTypeWhere) && (category === "plumbing" || category === "electricity" || category === "extermination")) {
        setError({ ...error, SubTypeWhere: t('modal.maintenance.validation.sub-type-where') });
        return false;
      } else if (Validation.empty(description)) {
        setError({ ...error, description: t('modal.maintenance.validation.description') });
        return false;
      }
      return true;
    };

    // fucntion to update Maintenance ticket
    const handleUpdateTicket = (e) => {
      e && e.preventDefault();
      if (
        note ||
        images.length > 0 ||
        heicImages.length > 0 ||
        // (!data.descriptionPresent && data.description) ||
        // (!data.subCategoryPresent && data.subCategory) ||
        statusChange ||
        isSchedule
      ) {
        let { category } = data;
        if(!category){
          return setError({ ...error, category: t('modal.maintenance.validation.category') });
        }
        // if (
        //   handleValidation({
        //     category,
        //     // subCategory,
        //     // description,
        //   })
        // ) {
          const formData = new FormData();
          note && formData.append("note", note);
          (note || images.length || heicImages.length) &&
            formData.append("zzCreatedByWeb", user.Email_01);
          if (statusChange) {
            formData.append("status", data.status);
            if (data.status === "Verified by Tenant") {
              formData.append("VerifiedBy", user.Email_01);
              formData.append(
                "VerifiedTS",
                moment().format("MM/DD/YYYY h:mm:ss A").toString()
              );
            }
          }
          
          // !data.descriptionPresent &&
            data.description &&
            formData.append("Description_Web", data.description);
          // !data.subCategoryPresent &&
            data.subCategory &&
            formData.append("subType", data.subCategory);
            data.SubTypeWhere &&
            formData.append("SubTypeWhere", data.SubTypeWhere);
          if (images.length) {   
            for (let i = 0; i < images.length; i++) {
              formData.append("upload", images[i]);
            }
          }
          if (heicImages.length) {
            for (let i = 0; i < heicImages.length; i++) {
              formData.append("upload", heicImages[i]);
            }
          }

          if (isSchedule) {
            formData.append(
              "Web_Schedule01",
              moment(date.date1).format("MM/DD/yyyy") + " " + timeSlot.tSlot1
            );
            formData.append(
              "Web_Schedule02",
              moment(date.date2).format("MM/DD/yyyy") + " " + timeSlot.tSlot2
            );
            formData.append(
              "Web_Schedule03",
              moment(date.date3).format("MM/DD/yyyy") + " " + timeSlot.tSlot3
            );
            formData.delete('upload');

          }
          formData.append("_id", data._id || newTicketData?._id)
          EditUpdateTicket(formData, (data)=> {  formatData(data);
            isSchedule && closeModal(true)
          });
          getDashboardData(()=>{});
          setLoading(false);
        }
      // } else {
      //   Notify("info", "Nothing to update Please update Something");
      // }
    };

    // function to add new ticket
    const handleAddTicket = () => {

      let { category, subCategory, SubTypeWhere, description } = data;
      if(category?.toLowerCase() === 'elevator'){
        subCategory = "Elevator"
        SubTypeWhere = "Elevator"
      }
      if (
        handleValidation({
          category,
          subCategory,
          SubTypeWhere,
          description,
        })
      ) {
        const formData = new FormData();

        formData.append(
          "type",
          category.charAt(0).toUpperCase() + category.slice(1)
        );
        formData.append("subType", subCategory);
        formData.append("SubTypeWhere", SubTypeWhere);
        formData.append("Description_Web", description);
        formData.append("zzModifiedByWeb", user.Email_01);
        if (images.length) {
          for (let i = 0; i < images.length; i++) {
            formData.append("upload", images[i]);
          }
        }
        if (heicImages.length) {
          for (let i = 0; i < heicImages.length; i++) {
            formData.append("upload", heicImages[i]);
          }
        }
        AddTicket(formData, onSucess, onError);
        setLoading(true);
      }
    };

    const onError =(err)=>{
      if(err?.response?.data?.data?.isHasExterminationSchedule === "1"){
        setModalError(err?.response?.data?.message)
      }
      setLoading(false);
      closeModal(false);
     }

    // callback function which runs on successfull adding of ticket
    const onSucess = (data) => {
      setNewTicketData(data)
      setRefreshData && setRefreshData(!resfreshData);
      setLoading(false);
      setIsSchedule(!isSchedule);
      getDashboardData(()=>{})
    };

    // function to change the status of ticket
    const setStatus = (value) => {
      setData({ ...data, status: value });
      setStatusText(value);
      !statusChange && setStatusChange(true);
    };

    // function to toggle maintenance scheduling and create new ticket on Next button
    const handleSchedule = () => {
      // let { category, subCategory, SubTypeWhere, description } = data;
      
      // if (handleValidation({ category, subCategory, SubTypeWhere, description })) {
        handleAddTicket();
        // setIsSchedule(!isSchedule);
      // }
    };

    const categoryTranslation = (text) => {
      return text === "Cleaning" ? 
        (t('maintenance-category.cleaning')) : (text === "Plumbing") ?
        (t('maintenance-category.plumbing')) : (text === "Extermination") ?
        (t('maintenance-category.extermination')) : (text === "Electricity") ?
        (t('maintenance-category.electricity')) : (text === "Scheduled") ?
        (t('maintenance-category.scheduled')) : (text === "Carpentry") ?
        (t('maintenance-category.carpentry')) : (text === "Landscaping") ?
        (t('maintenance-category.landscaping')) : text
    }

    const subCategoryTranslation = (text) => {
      return text === "Leaks" ? 
        (t('maintenance-subCategory.leaks')) : (text === "Air Conditioning") ?
        (t('maintenance-subCategory.ac')) : (text === "Heat") ?
        (t('maintenance-subCategory.heat')) : (text === "Front Yard") ?
        (t('maintenance-subCategory.frontYard')) : (text === "Back Yard") ?
        (t('maintenance-subCategory.backYard')) : (text === "Side Yard") ?
        (t('maintenance-subCategory.sideYard')) : (text === "Rats/Mice") ?
        (t('maintenance-subCategory.rat')) : (text === "Cockroaches") ?
        (t('maintenance-subCategory.cockroaches')) : (text === "Ants") ?
        (t('maintenance-subCategory.ants')) : (text === "Doors") ?
        (t('maintenance-subCategory.doors')) : (text === "Walls") ?
        (t('maintenance-subCategory.walls')) : (text === "Ceiling") ?
        (t('maintenance-subCategory.ceiling')) : (text === "Lights") ?
        (t('maintenance-subCategory.lights')) : (text === "Sockets") ?
        (t('maintenance-subCategory.sockets')) : (text === "Entryway") ?
        (t('maintenance-subCategory.entryway')) : (text === "Hallway") ?
        (t('maintenance-subCategory.hallway')) : (text === "Trash Area") ?
        (t('maintenance-subCategory.trash')) : (text === "Large Debris") ?
        (t('maintenance-subCategory.debris')) : text
    }

    const whereListTranslation = (text) => {
      return text === "Bedroom" ? 
        (t('maintenance-where.bedroom')) : (text === "Bathroom") ?
        (t('maintenance-where.bathroom')) : (text === "Kitchen") ?
        (t('maintenance-where.kitchen')) : (text === "Living Room") ?
        (t('maintenance-where.living-room')) : (text === "Hallway") ?
        (t('maintenance-where.hallway')) : (text === "Whole Unit") ?
        (t('maintenance-where.whole-unit')) : text
    }

    return (
      <div className="maintenance-container">
        {!isSchedule ? (
          !modalDialog ? (
            <>
              {edit && (
                <Nav tabs className="nav-container">
                  <NavItem className="hover-pointer">
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => setActiveTab("1")}
                    >
                      <span className="d-sm-block">{t('modal.maintenance.info')}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="hover-pointer">
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => setActiveTab("2")}
                    >
                      <span className="d-sm-block">{t('modal.maintenance.notes')}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="hover-pointer">
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => setActiveTab("3")}
                    >
                      <span className="d-sm-block">{t('modal.maintenance.photos')}</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              )}
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="row m-1">
                    {!edit && !images.length && (
                      <div className="note note-warning note-with-right-icon p-5">
                        <div className="note-icon">
                          <i className="fa fa-lightbulb"></i>
                        </div>
                        <div className="note-content text-right">
                          <h5>
                            <b>{t('modal.maintenance.info-text')}</b>
                          </h5>
                        </div>
                      </div>
                    )}

                    <label className="col-md-12 col-form-label">
                    {t('modal.maintenance.category')}
                      <small className="text-danger"> *</small>
                    </label>
                    <div className="col-md-12">
                      <select
                        disabled={edit}
                        className={`form-control ${
                          error.category && "border border-danger"
                        }`}
                        value={data.category && data.category.toLowerCase()}
                        onChange={(e) =>
                          setData({ ...data, category: e.target.value })
                        }
                        onFocus={(e) => setError({ ...error, category: "" })}
                      >
                        <option value="">{t('modal.maintenance.category')}</option>

                        {categoryList &&
                          Object.keys(categoryList).map((cat, i) => (
                            <option value={cat} key={i}>
                              {categoryTranslation(cat.charAt(0).toUpperCase() + cat.substring(1))}
                            </option>
                          ))}
                      </select>
                    </div>
                    <p className="col-md-12 text-danger">{error.category}</p>
                    {
                     data?.category?.toLowerCase() !== 'elevator' ? 
                    <>
                    <label className="col-md-12 col-form-label">
                    {t('modal.maintenance.sub-category')}
                      {!edit && <small className="text-danger"> *</small>}
                    </label>
                    <div className="col-md-12">
                      <select
                        disabled={data.subCategoryPresent || edit}
                        className={`form-control ${
                          error.subCategory && "border border-danger"
                        }`}
                        value={data.subCategory}
                        onChange={(e) =>
                          setData({ ...data, subCategory: e.target.value })
                        }
                        onFocus={(e) => setError({ ...error, subCategory: "" })}
                      >
                        <option value="">{t('modal.maintenance.sub-category')}</option>
                        {data.category &&
                          categoryList?.[data.category.toLowerCase() || ""]?.map(
                            (item, i) => (
                              <option
                                className="text-capitalize"
                                key={i}
                                value={item}
                              >
                                {subCategoryTranslation(item)}
                              </option>
                            )
                          )}
                      </select>
                    </div>
                    <p className="col-md-12 text-danger">{error.subCategory}</p>
                    
                      <label className="col-md-12 col-form-label">
                    {t('modal.maintenance.where')}
                      {!edit && <small className="text-danger"> *</small>}
                    </label>
                    <div className="col-md-12">
                      <select
                        disabled={data.SubTypeWherePresent || edit}
                        className={`form-control ${
                          error.SubTypeWhere && "border border-danger"
                        }`}
                        value={data.SubTypeWhere}
                        onChange={(e) =>{
                          setData({ ...data, SubTypeWhere: e.target.value })
                        }}
                        onFocus={(e) => setError({ ...error, SubTypeWhere: "" })}
                      >
                        <option value="">{t('modal.maintenance.where')}</option>
                        {
                          whereList.map(
                            (item, i) => (
                              <option
                                className="text-capitalize"
                                key={i}
                                value={item}
                              >
                                {whereListTranslation(item)}
                              </option>
                            )
                          )}
                      </select>
                    </div>
                    <p className="col-md-12 text-danger">{error.SubTypeWhere}</p>
                    </>
                     : null
                    }
                  </div>

                  <div className="row m-1">
                    <label className="col-md-12 col-form-label">
                    {t('modal.maintenance.description')}
                      <small className="text-danger"> *</small>
                    </label>
                    <div className="col-md-12">
                      <textarea
                        disabled={data.descriptionPresent || edit}
                        className={
                          error.description
                            ? "form-control border border-danger"
                            : "form-control"
                        }
                        rows="4"
                        value={data.description}
                        onChange={(e) =>
                          setData({ ...data, description: e.target.value })
                        }
                        onFocus={(e) => setError({ ...error, description: "" })}
                      ></textarea>
                    </div>
                    <p className="col-md-12 text-danger">{error.description}</p>

                    {!data.isSurveyDone && data.isVerifiedByTenant && !data.older90 && (
                      <div className="form-group row col-md-12 align-items-center">
                        <div className="col-md-10">
                          <button
                            className="btn btn-warning"
                            type="button"
                            onClick={(e) => {
                              setStatusType("Verified by Tenant");
                              setModalDialog(true);
                            }}
                          >
                            {t('modal.maintenance.tell-us')}
                          </button>
                        </div>
                      </div>
                    )}

                    {data && data.status && !data.older90 && (
                      <div className="form-group row col-md-12 align-items-center">
                        <label className="col-md-2 col-form-label">
                          <b>{t('modal.maintenance.status')}</b>
                        </label>
                        <div className="col-md-10">
                          <button
                            className="btn btn-primary"
                            type="button"
                            value="Verified by Tenant"
                            onClick={(e) => setStatus(e.target.value)}
                          >
                            {t('modal.maintenance.verified')}
                          </button>
                          <button
                            className="btn btn-default m-l-5"
                            type="button"
                            value="Re-Opened by Tenant"
                            onClick={(e) => setStatus(e.target.value)}
                          >
                            {t('modal.maintenance.reopen')}
                          </button>
                          <button
                            className="btn btn-warning m-l-5"
                            type="button"
                            value="Verified by Tenant"
                            onClick={(e) => {
                              setStatus(e.target.value)
                            }}
                          >
                            {t('modal.maintenance.tell-us')}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  {!edit && (
                    <Dropzone
                      onDrop={(acceptedFiles) => handleImage(acceptedFiles)}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div id="row" className="dropzone needsclick">
                              <div className="d-handleSubmitflex align-items-center flex-wrap">
                                {imgdata.length > 0 ? (
                                  imgdata.map((item, i) => (
                                    <div key={i}>
                                      <img
                                        className="m-10"
                                        src={item.imagePreviewUrl}
                                        width="120px"
                                        height="100px"
                                        alt="alt-img"
                                      />
                                    </div>
                                  ))
                                ) : (
                                  <form>
                                    <div className="dz-message needsclick">
                                      Drop files <b>here</b> or <b>click</b> to
                                      upload.
                                    </div>
                                  </form>
                                )}
                              </div>
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  )}

                    {/* <button
                      className="btn btn-primary"
                      type="button"
                      value="Verified by Tenant"
                      onClick={(e) => {
                        setStatus(e.target.value)
                      }}
                    >
                      {t('modal.maintenance.tell-us')}
                    </button> */}
                  {!data.older90 && <p className="text-right m-b-0">
                    {!loading ? (
                      ((!edit ||
                        !data.descriptionPresent ||
                        !data.subCategoryPresent) && (
                        <>
                          <button
                            className="btn btn-primary"
                            onClick={edit ? handleUpdateTicket : handleSchedule}
                          >
                            {edit ? `${t('modal.maintenance.submit')}` : `${t('modal.maintenance.next')}`}
                          </button>
                        </>
                      )) || (
                        <>
                          
                          {(statusType === "New" ||
                            statusType === "Re-Opened by Tenant") && (
                            <button
                              className="btn btn-primary"
                              onClick={() => {setIsSchedule(true); setCross(false);}}
                            >
                              {t('modal.maintenance.reschedule')}
                            </button>
                          )}
                          <button
                            className="btn btn-white m-l-5"
                            onClick={() => closeModal(false)}
                          >
                            {t('modal.maintenance.close')}
                          </button>
                        </>
                      )
                    ) : (
                      <i className="fas fa-spinner fa-pulse fa-3x text-orange-transparent-8"></i>
                    )}
                  </p>}
                </TabPane>
                <TabPane tabId="2">
                  <div>
                    <div className="widget-chat widget-chat-rounded">
                      <div
                        className="widget-chat-body"
                        data-scrollbar="true"
                        data-height="235px"
                      >
                        {data &&
                          data.notes &&
                          data.notes.filter(x=> !x["Maintenance_NOTE_create::ArchiveURL"] && x["Maintenance_NOTE_create::Note"])?.map((item, i) =>
                            !item["Maintenance_NOTE_create::isAddedViaWeb"] ? (
                              <div
                                className="widget-chat-item with-media left"
                                key={i}
                              >
                                <div className="widget-chat-media">
                                  <img
                                    alt="alt-img"
                                    src="/assets/img/logo/info.png"
                                  />
                                </div>
                                <div className="widget-chat-info">
                                  <div className="widget-chat-info-container">
                                    <div className="widget-chat-name text-indigo">
                                      {
                                        item[
                                          "Maintenance_NOTE_create::zzModifiedByWeb"
                                        ]
                                      }
                                    </div>
                                    <div className="widget-chat-message">
                                      {item["Maintenance_NOTE_create::Note"]}
                                    </div>
                                    <div
                                      className="widget-chat-time"
                                      style={{ position: "block" }}
                                    >
                                      {item[
                                            "Maintenance_NOTE_create::zzCreatedTS"
                                          ]}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="widget-chat-item right" key={i}>
                                <div className="widget-chat-info">
                                  <div className="widget-chat-info-container">
                                    <div className="widget-chat-name text-indigo">
                                      {
                                        item[
                                          "Maintenance_NOTE_create::zzModifiedByWeb"
                                        ]
                                      }
                                    </div>
                                    <div className="widget-chat-message">
                                      {item["Maintenance_NOTE_create::Note_c"] || item["Maintenance_NOTE_create::Note"]}
                                    </div>
                                    <div className="widget-chat-time">
                                      {item[
                                            "Maintenance_NOTE_create::zzCreatedTS"
                                          ]}
                                    </div>
                                  </div>
                                </div>
                                <div className="widget-chat-media">
                                  <img
                                    className="width-full height-full"
                                    alt="alt"
                                    src={
                                      (user && user.Photo_Thumbnail) ||
                                      "/assets/img/user/user.png"
                                    }
                                  />
                                </div>
                              </div>
                            )
                          )}
                      </div>
                      {["New", "Scheduled", "Scheduled - Confirmed", "Re-Opened by Tenant"].includes(data.Status) && <div className="widget-input widget-input-rounded">
                        <div className="widget-input-container">
                          <form onSubmit={handleUpdateTicket}>
                            <div className="widget-input-box">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder={t('modal.maintenance.write-note')}
                                value={note}
                                onChange={(e) =>
                                  setNote(
                                    e.target.value.toString().slice(0, 100)
                                  )
                                }
                              />
                            </div>
                            <div className="widget-input-icon">
                              <b>{`${note.length}/100`}</b>
                            </div>
                            <div className="widget-input-icon">
                              <i
                                disabled={loading}
                                className="ion-md-send text-blue-lighter fa-2x hover-pointer"
                                onClick={handleUpdateTicket}
                              />
                            </div>
                          </form>
                        </div>
                      </div>}
                     
                    </div>
                    {loading && (
                      <p className="text-right">
                        <i className="fas fa-spinner fa-pulse fa-3x text-orange-transparent-8"></i>
                      </p>
                    )}
                  </div>
                </TabPane>{" "}

                <TabPane tabId="3">
                  <div className="row row-space-2 images-container">
                  
                    {["New", "Scheduled", "Scheduled - Confirmed", "Re-Opened by Tenant"].includes(data.Status) && <div className="col-12">
                      <Dropzone
                        onDrop={(acceptedFiles) => handleImage(acceptedFiles)}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div id="row" className="dropzone needsclick">
                                <div className="d-flex align-items-center flex-wrap">
                                  {imgdata.length > 0 ? (
                                    imgdata.map((item, i) => (
                                      <div key={i}>
                                        <img
                                          className="m-10 image-preview"
                                          src={item.imagePreviewUrl}
                                          alt="alt-img"
                                        />
                                      </div>
                                    ))
                                  ) : (
                                    <form>
                                      <div className="dz-sm-message needsclick">
                                        Drop files <b>here</b> or <b>click</b>{" "}
                                        to upload.
                                      </div>
                                    </form>
                                  )}
                                </div>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>} 
                    {data &&
                      data.notes &&
                      data.notes.filter(x=> x['Maintenance_NOTE_create::ArchiveURL'] && !x['Maintenance_NOTE_create::Note'])?.map((item, i) => (
                        <div
                          key={i}
                          className="col-2 mt-3"
                        >
                          <a
                            href={`/gallery?${item["Maintenance_NOTE_create::ArchiveURL"]}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="widget-card widget-card-rounded square m-b-2"
                          >
                            <div
                              className="widget-card-cover"
                              style={{
                                backgroundImage: `url(${
                                  item[
                                    "Maintenance_NOTE_create::ArchiveURL"
                                  ] !== "?"
                                    ? item[
                                        "Maintenance_NOTE_create::ArchiveURL"
                                      ]
                                    : "/assets/img/gallery/Default_Image_Thumbnail.png"
                                })`,
                              }}
                            ></div>
                          </a>
                        </div>
                      ))}
                  </div>
                  {loading && (
                    <p className="text-right">
                      <i className="fas fa-spinner fa-pulse fa-3x text-orange-transparent-8"></i>
                    </p>
                  )}
                </TabPane>
              </TabContent>
            </>
          ) : (
            <Survey
              data={{
                type: statusType,
                recordId: data.recordId,
                zzId_MAIN: data.zzId_MAIN,
                _id: data?._id
              }}
              setIsSchedule={setIsSchedule}
              closeModal={closeModal}
            />
          )
        ) : (
          <MaintenanceSchedule
            closeModal={closeModal}
            date={date}
            setDate={setDate}
            loading={loading}
            timeSlot={timeSlot}
            setTimeSlot={setTimeSlot}
            error={error}
            setError={setError}
            edit={edit}
            handleUpdateTicket={handleUpdateTicket}
          />
        )}
      </div>
    );
  }
);

const mapStateToProps = ({ USER_REDUCER }) => {
  return { user: USER_REDUCER.userData };
};
const MaintenanceFormWrapper = (props) => (
  <WrapperComponent>
    <Maintainence {...props} />
  </WrapperComponent>
);
export default connect(mapStateToProps, {
  AddTicket,
  GetTicket,
  EditUpdateTicket,
  getDashboardData
})(withRouter(MaintenanceFormWrapper));