import React, { useContext, useEffect } from "react";
import { PageSettings } from "../../config/page-settings";
import styles from "./OnBoardTenant.module.css";
import thankyouSVG from "./../../assets/img/svg/circle-check-solid.svg";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

const ThankYou = () => {
  const context = useContext(PageSettings);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const startNew = queryParams.get("startNew");
  const history = useHistory();

  useEffect(() => {
    context.handleSetPageSidebar(false);
    context.handleSetPageHeader(false);
  }, []);
  return (
    <div className="container">
      <div
        className={`row justify-content-center align-items-center ${styles?.onboard_thank_you}`}
      >
        <div
          className={`col-xs-12 col-sm-8 col-md-8 col-lg-6 d-flex flex-column justify-content-center align-items-center`}
        >
          <img src={thankyouSVG} alt="" width={200} />
          <h1 className="mt-3 text-center">Thank you</h1>
          {startNew && <button className="btn btn-secondary btn-lg mt-5" onClick={()=> history.push("/onboard-tenant")}>Start New Application</button>}
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
