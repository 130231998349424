import React, { useEffect, useState, memo, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { connect } from "react-redux";
import moment from "moment";
import { GetPaymentData } from "../../redux/actions/payments";
import WrapperComponent from "../wrapperComponent";
import { PageSettings } from "../../config/page-settings";
import { Spinner, Input } from "reactstrap";

const PaymentChart = memo(({ GetPaymentData }) => {
  const context = useContext(PageSettings);

  const [countsPaymentData, setCountsPaymentData] = useState({
    labels: [],
    series: [],
  });
  const [totalPaymentsData, setTotalPaymentsData] = useState({
    labels: [],
    series: [],
  });
  const [totalCountsPayments, setCountsPaymentTotal] = useState(0);
  const [totalPayments, setTotalPayments] = useState(0);
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchChartData = async () => {
    try {
      setLoading(true);
      const payload = {
        startDate: date.startDate,
        endDate: date.endDate,
      };
      await GetPaymentData(payload, (data) => {
        if (data) {
          setCountsPaymentData({
            labels: data.result.map((item) => item._id),
            series: data.result.map((item) => item.countPayments),
          });
          setCountsPaymentTotal(
            data.result.reduce((acc, item) => acc + item.countPayments, 0)
          );

          setTotalPaymentsData({
            labels: data.result.map((item) => item._id),
            series: data.result.map((item) => item.totalPayments),
          });
          setTotalPayments(
            data.result.reduce((acc, item) => acc + item.totalPayments, 0)
          );
        } else {
          setError("Failed to fetch data.");
        }
        setLoading(false);
      });
    } catch {
      setError("Failed to load chart data.");
    }
  };

  useEffect(() => {
    context.handleSetPageSidebar(false);
    context.handleSetPageHeader(false);
    // const startOfWeek = moment().startOf("isoWeek").format("MM/DD");
    // const endOfWeek = moment().endOf("isoWeek").format("MM/DD");
    const startOfWeek = moment().startOf("isoWeek").format("MM/DD/YYYY");
    const endOfWeek = moment().endOf("isoWeek").format("MM/DD/YYYY");
    setDate({ startDate: startOfWeek, endDate: endOfWeek });
    fetchChartData();
  }, []);

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    const formattedDate = moment(value).format("MM/DD/YYYY");
    setDate((prevDate) => ({ ...prevDate, [name]: formattedDate }));
  };

  return (
    <>
    <div className="container-fluid" style={{marginTop: -20}}>
      {loading ? (
        <div className="row mt-5 justify-content-center align-items-center">
          <Spinner color="primary" size="lg" type="grow" />
        </div>
      ) : (
        <div className="row">
          <div className="col-12 col-md-6 d-flex flex-column align-items-center">
            <h5 className="modal-title text-center">
              {`${totalCountsPayments} Payments Taken ${moment(date.startDate).format("MM/DD/YYYY")} - ${moment(date.endDate).format("MM/DD/YYYY")}`}
            </h5>
            <ReactApexChart
              options={{
                labels: countsPaymentData.labels,
                chart: { sparkline: { enabled: false } },
                legend: { show: false },
                dataLabels: {
                  enabled: true,
                  formatter: (val, opts) => `${val.toFixed(1)}%`,
                  dropShadow: { enabled: false },
                },
                plotOptions: {
                  pie: {
                    expandOnClick: false,
                    minAngleToShowLabel: 10,
                    donut: {
                      size: "40%",
                    },
                  },
                },
                tooltip: {
                  y: {
                    formatter: (val) => `${val} Payments Counts`,
                  },
                },
                responsive: [
                  {
                    breakpoint: 1400,
                    options: {
                      chart: {
                        width: "700",
                      },
                    },
                  },
                  {
                    breakpoint: 1200,
                    options: {
                      chart: {
                        width: "500",
                      },
                    },
                  },
                  {
                    breakpoint: 992,
                    options: {
                      chart: {
                        width: "450",
                      },
                    },
                  },
                  {
                    breakpoint: 786,
                    options: {
                      chart: {
                        width: "500",
                      },
                    },
                  },
                  {
                    breakpoint: 576,
                    options: {
                      chart: {
                        width: "350",
                      },
                    },
                  },
                ],
              }}
              series={countsPaymentData.series}
              type="donut"
              width={800}
            />
          </div>
          <div className="col-12 col-md-6 d-flex flex-column align-items-center">
            <h5 className="modal-title text-center">
            {`$${(Math.round(totalPayments * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Amount Taken ${moment(date.startDate).format("MM/DD/YYYY")} - ${moment(date.endDate).format("MM/DD/YYYY")}`}
            </h5>
            <ReactApexChart
              options={{
                labels: totalPaymentsData.labels,
                chart: { sparkline: { enabled: false } },
                legend: { show: false },
                dataLabels: {
                  enabled: true,
                  formatter: (val, opts) => `${val.toFixed(1)}%`,
                  dropShadow: { enabled: false },
                },
                plotOptions: {
                  pie: {
                    expandOnClick: false,
                    donut: {
                      size: "40%",
                    },
                  },
                },
                tooltip: {
                  y: {
                    formatter: (val) => `${val} Payments Amount`,
                  },
                },
                responsive: [
                  {
                    breakpoint: 1400,
                    options: {
                      chart: {
                        width: "700",
                      },
                    },
                  },
                  {
                    breakpoint: 1200,
                    options: {
                      chart: {
                        width: "500",
                      },
                    },
                  },
                  {
                    breakpoint: 992,
                    options: {
                      chart: {
                        width: "450",
                      },
                    },
                  },
                  {
                    breakpoint: 786,
                    options: {
                      chart: {
                        width: "500",
                      },
                    },
                  },
                  {
                    breakpoint: 576,
                    options: {
                      chart: {
                        width: "350",
                      },
                    },
                  },
                ],
              }}
              series={totalPaymentsData.series}
              type="donut"
              width={800}
            />
          </div>
        </div>
      )}
    </div>
    <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-6 col-12 mb-3">
              <label htmlFor="startDate">Start Date:</label>
              <Input
                id="startDate"
                type="date"
                name="startDate"
                value={
                  date.startDate
                    ? moment(date.startDate, "MM/DD/YYYY").format("YYYY-MM-DD")
                    : ""
                }
                onChange={handleDateChange}
                className="form-control"
              />
            </div>
            <div className="col-md-4 col-sm-6 col-12 mb-3">
              <label htmlFor="endDate">End Date:</label>
              <Input
                id="endDate"
                type="date"
                name="endDate"
                value={
                  date.endDate
                    ? moment(date.endDate, "MM/DD/YYYY").format("YYYY-MM-DD")
                    : ""
                }
                onChange={handleDateChange}
                className="form-control"
              />
            </div>
            <div className="col-md-4 col-12 d-flex align-items-end mb-3">
              <button
                onClick={fetchChartData}
                className="btn btn-primary w-100"
              >
                Apply
              </button>
            </div>
          </div>
        </div></>
  );
});

const mapStateToProps = ({}) => ({});

const PaymentChartWrapper = (props) => (
  <WrapperComponent>
    <PaymentChart {...props} />
  </WrapperComponent>
);

export default connect(mapStateToProps, { GetPaymentData })(
  PaymentChartWrapper
);
