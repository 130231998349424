import Api from "../api/api";
import { Notify } from "../../helpers/notification";
import { getDashboardData, getProfile } from "./user";

const PAYMENT_LOADING = () => {
  return { type: "PAYMENT_LOADING" };
};

const PAYMENT_COMPLETE = () => {
  return { type: "PAYMENT_COMPLETE" };
};

export const GetAllPayment = (cb,page) => (dispatch) => {
  dispatch({type: "SET_APP_LOADING", payload: true});
  Api.get(`api/payment/getPayment/`+page)
    .then((response) => {
      cb && cb(response.data.data);
      dispatch({ type: "ALL_PAYMENT", payload: response.data.data });
    })
    .catch((err) => {
      console.log({err})
      cb && cb(false);
    })
    .catch(() => {
      Notify('danger', "Something Bad Happened :(");
    })
    .finally(()=>{
      dispatch({type: "SET_APP_LOADING", payload: false});
    });
};

export const MakePayment = (payload, cb) => (dispatch) => {
  dispatch(PAYMENT_LOADING());
  Api.post("api/payment/capturePayment", payload)
    .then((response) => {
      dispatch(GetAllPayment(()=> {}, 1));
      dispatch(PAYMENT_COMPLETE());
      dispatch(getDashboardData(()=>{}))
      cb && cb(response.data);
      // process.env.NODE_ENV == "development" && Notify("success", response.data.message);
    })
    .catch((err) => {
      console.log({err})
      dispatch(PAYMENT_COMPLETE());
      Notify("danger", "Something Bad Happened :(");
    })
};

export const ViewPayment = (id, reqParams) => (dispatch) => {
  dispatch(PAYMENT_LOADING());
  // const options = {
  //   onDownloadProgress: progressEvent => {
  //     const total = parseFloat(progressEvent.total)
  //     const current = progressEvent.loaded
  
  //     let percentCompleted = Math.floor(current / total * 100)
  //     console.log('completed: ', percentCompleted)
  //   }
  // };
  Notify("info", "...generating PDF please wait!")
  Api.get(`api/payment/viewPayment/${id}?${reqParams}`)
    .then((response) => {
      dispatch(PAYMENT_COMPLETE());
      dispatch({ type: "VIEW_PAYMENT", payload: response.data.data });
    })
    .catch(() => {
      dispatch(PAYMENT_COMPLETE());
    })
    .catch(() => {
      Notify("danger", "Something Bad Happened :(");
    });
};


export const handleRecurringPayment = (payload, cb) => dispatch => {
  dispatch(PAYMENT_LOADING());
  return Api.post("api/payment/recurringPayment", payload)
    .then(() => {
      cb && cb (null, true);
      dispatch({ type: 'PAYMENT_SUBMITTED' });
      dispatch(getProfile());
    })
    .catch((error) => {
      cb && cb (error.response.data, false);
      dispatch(PAYMENT_COMPLETE());
    })
    .catch(() => {
      Notify("danger", "Something Bad Happened :(");
    });
};

export const cancelRecurringPayment = (id, payload, cb) => async dispatch => {
  dispatch(PAYMENT_LOADING());
  try {
    await Api.post(`api/payment/cancelrecurringPayment/${id}`, payload);
    cb && cb();
    Notify('success', "Recurring Billing Canceled Successfully!");
    dispatch({ type: 'UPDATE_RECURRING_PAYMENT', payload: id });
    dispatch(PAYMENT_COMPLETE());
  } catch (err) {
    cb && cb();
    dispatch(PAYMENT_COMPLETE());
  }
}

export const clearPaymentDetails = () => (dispatch) => {
  dispatch({ type: "CLEAR_PAYMENTDETAILS" });
};

export const GetPaymentData = (payload, cb) => (dispatch) => {
  Api.post(`api/payment/fetch/this-week-data`, payload)
    .then((response) => {
      cb && cb(response.data.data);
    })
    .catch((err) => {
      console.log(err)
      cb && cb(false);
    })
    .catch(() => {
      Notify('danger', "Something Bad Happened :(");
    });
};
