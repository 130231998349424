import React from "react";
import { Route } from "react-router-dom";
import { PageSettings } from "./../../config/page-settings.js";
import SidebarNavList from "./sidebar-nav-list.jsx";
import {Menu, spanishMenu} from "./menu.jsx";
import { connect } from "react-redux";
import { ChangeLease } from "../../redux/actions/user";
import moment from 'moment';
import { withTranslation } from 'react-i18next'; 
class SidebarNav extends React.Component {
  static contextType = PageSettings;

  constructor(props) {
    super(props);
    this.state = {
      active: -1,
      clicked: -1,
      menus: Menu,
      modalToggle: false,
      refreshMenu: this.props.t('sidebar.dashboard')
    };
  }

  componentDidMount () {
    localStorage.getItem('i18nextLng') === 'es' ? this.setState({menus: spanishMenu}) : this.setState({menus: Menu})
  }

  componentDidUpdate (prevProps, prevState) {

    if ( prevState.refreshMenu !== this.props.t('sidebar.dashboard')) {
      let text = this.props.t('sidebar.dashboard')
      this.setState({
        refreshMenu : text,
        menus: text == "Dashboard" ? Menu : spanishMenu
      })
    }
  }

  handleExpand(e, i, match) {
    e.preventDefault();

    if (this.state.clicked === -1 && match) {
      this.setState((state) => ({ active: -1, clicked: 1 }));
    } else {
      this.setState((state) => ({ active: state.active === i ? -1 : i, clicked: 1 }));
    }
  }

  handleSidebarSearch = (e) => {
    let searchValue = e.target.value;
    searchValue = searchValue.toLowerCase();

    this.setState((state) => {
      let newMenus = [];
      if (searchValue !== "") {
        newMenus = Menu.filter((item) => {
          let title = item.title;
          title = title.toLowerCase();
          if (title.search(searchValue) > -1) {
            item.search = true;
            return true;
          } else {
            if (item.children) {
              for (var i = 0; i < item.children.length; i++) {
                let title2 = item.children[i]["title"];
                title2 = title2.toLowerCase();

                if (title2.search(searchValue) > -1) {
                  item.search = true;
                  return true;
                }
              }
            }
            return false;
          }
        });
      } else {
        newMenus = Menu.filter((item) => {
          item.search = false;
          return true;
        });
      }
      return {
        menus: newMenus,
      };
    });
  }

  handleSwitchLease = (e, zzId) => {
    e.stopPropagation();
    zzId && this.props.ChangeLease({ leaseId: zzId, client: 1 });
  }

  toggleModal = () => {
    this.setState(state => ({ modalToggle: !state.modalToggle })) 
  }

  render() {
    return (
      <ul className="nav">
        {this.context.pageSidebarSearch && (
          <li className="nav-search">
            <input
              type="text"
              className="form-control"
              placeholder="Sidebar menu filter..."
              onKeyUp={this.handleSidebarSearch}
            />
          </li>
        )}
        {this.state.menus?.filter((item) => !(item.path === '/maintenance' && this.props.isMaintenanceBlocked === "1"))?.map((menu, i) => (
          <Route
            path={menu.path}
            exact={menu.exact}
            key={i}
            children={({ match }) => (
              <SidebarNavList
                data={menu}
                key={i}
                expand={(e) => this.handleExpand(e, i, match)}
                active={i === this.state.active}
                clicked={this.state.clicked}
                handleClose={this.props.handleClose}
              />
            )}
          />
        ))}
        {this.props.Leases && this.props.Leases.length > 1 && (
          <>
            <hr className="bg-white-transparent-4" />
            <Route
              path={"#"}
              children={({ match }) => (
                <SidebarNavList
                  data={{
                    path: "#",
                    icon: "fa fa-home",
                    title: "Switch Lease",
                    children: this.props.Leases.map((item, i) => ({
                      path: "#",
                      title: `${item["People_PROP_LeasePeople::Address_Address01_finalDisplay"] + " UNIT " +
                        item["People_LESE_LeasePeople::UnitNumber"]
                      }`,
                      zzId: item["People_LEPE_LeasePeople::zzid_LESE"],
                    })),
                  }}
                  handleSwitchLease={this.handleSwitchLease}
                  expand={(e) => this.handleExpand(e, 0, match)}
                  activeLease={this.props.activeLease}
                  handleClose={this.props.handleClose}
                  active={0 === this.state.active}
                  clicked={this.state.clicked}
                />
              )}
            />
          </>
        )}
        {!this.props.isNotticeToVacate && this.props?.Leases?.length>0 && 
          <>
            <hr className="bg-white-transparent-4" />
            <Route
              path={"#"}
              children={({ match }) => (
                <SidebarNavList
                  data={{
                    path: "#",
                    icon: "fa fa-home",
                    title: "Notice To Vacate",
                    label: 'New'
                  }}
                  toggleModal={this.toggleModal}
                  modalToggle={this.state.modalToggle}
                  expand={(e) => this.handleExpand(e, 0, match)}
                  activeLease={this.props.activeLease}
                  handleClose={this.props.handleClose}
                  active={0 === this.state.active}
                  clicked={this.state.clicked}
                />
              )}
            />
          </>
        }
      </ul>
    );
  }
}

const mapStateToProps = ({ USER_REDUCER }) => {
  return {
    Leases: USER_REDUCER.AllLeases,
    activeLease: USER_REDUCER.userData && USER_REDUCER.userData.activeLease,
    isNotticeToVacate: USER_REDUCER.userData?.leaseDetails?.isNotticeToVacate,
    isMaintenanceBlocked: USER_REDUCER?.userData?.leaseDetails && USER_REDUCER.userData?.leaseDetails['Lease_PROP::isMaintenanceBlcked']
  };
};

export default connect(mapStateToProps, { ChangeLease })(withTranslation()(SidebarNav));
