import React from "react";

import MaintenanceList from "../pages/maintenance/MaintenanceList.js";
import Dashboard from "./../pages/dashboard/dashboard-v2";
import Login from "./../pages/user/login-v2.js";
import Payments from "./../pages/payments/paymentsList";
import Parkings from "./../pages/parking/parkingList";
import People from "./../pages/people/peopleList";
import Profile from "../pages/user/profile.js";
import Gallery from "../pages/gallery";
import VerifyEmail from '../pages/verifyEmail'
import NotFound from "../components/extra/extra-error";
import LoginDash from "../pages/loginDash";
import OfficeDash from "../pages/dashboard/officedash";
import Florio_admin from "../pages/user/florio_admin.js";
import OnBoardTenant from "../pages/OnBoardTenant/index.jsx";
import ThankYou from "../pages/OnBoardTenant/ThankYou.jsx";
import DeleteTenantRecords from "../pages/OnBoardTenant/DeleteTenantRecords.jsx";
import NotSecureMaintainance from "../pages/maintenance/NotSecureMaintainance.js";
import NoticeToVacatePage from "../pages/NoticeToVacatePage/index.jsx";
import RecordUserResponseOnVacateEmail from "../pages/NoticeToVacatePage/RecordUserResponseOnVacateEmail.jsx";
import NoticeToVacateThankYou from "../pages/NoticeToVacatePage/ToticeToVacateThankYou.jsx";
import OnBoardTenantFromOffice from "../pages/OnBoardTenant/OnBoardTenantFromOffice.jsx";
import MaintainanceTicketChart from "../pages/maintainanceTicketChart/maintainanceTicketChart.jsx";
import PaymentChart from "../pages/paymentChart/paymentChart.jsx";


const routes = [
  {
    path: "/",
    title: "Login",
    exact: true,
    protected: false,
    component: () => <Login />,
  },
  {
    path: "/onboard-tenant/:_id",
    exact: true,
    title: "OnBoard Tenant",
    protected: false,
    component: () => <OnBoardTenant />,
  },
  {
    path: "/onboard-tenant/:_id/delete",
    exact: true,
    title: "OnBoard Tenant",
    protected: false,
    component: () => <DeleteTenantRecords />,
  },
  {
    path: "/onboard-tenant",
    exact: true,
    title: "OnBoard Tenant",
    protected: false,
    component: () => <OnBoardTenantFromOffice />,
  },
  {
    path: "/thank-you",
    exact: true,
    title: "Thank You",
    protected: false,
    component: () => <ThankYou />,
  },
  {
    path: "/verifyemail",
    title: "verifyEmail",
    exact: true,
    protected: false,
    component: () => <VerifyEmail />,
  },
  {
    path: "/dashboard",
    title: "Dashboard",
    exact: true,
    protected: true,
    component: () => <Dashboard />,
  },
  {
    path: "/maintenance",
    exact: true,
    title: "Maintenance",
    protected: true,
    component: () => <MaintenanceList />,
  },
  {
    path: "/payments",
    exact: true,
    title: "Payments",
    protected: true,
    component: () => <Payments />,
  },
  // {
  //   path: "/profile",
  //   exact: true,
  //   title: "Profile",
  //   protected: true,
  //   component: () => <Profile />,
  // },
  {
    path: "/parking",
    exact: true,
    title: "Parking",
    protected: true,
    component: () => <Parkings />,
  },
  {
    path: "/people",
    exact: true,
    title: "People",
    protected: true,
    component: () => <People />,
  },
  {
    path: "/gallery",
    exact: true,
    title: "Gallery",
    protected: false,
    component: (props) => <Gallery {...props} />,
  },
  {
    path: "/officedash",
    exact: true,
    title: "Office Dashboard",
    protected: false,
    isStaff: true,
    component: () => <OfficeDash />,
  },
  {
    path: "/login",
    exact: true,
    title: "Login",
    protected: false,
    component: () => <LoginDash />,
  },
  {
    path: "/florio_admin",
    exact: true,
    title: "Login",
    protected: false,
    component: () => <Florio_admin />,
  },
  {
    path: "/maintainance-ticket-details/:ticket_zzid",
    exact: true,
    title: "Maintainance Ticket",
    protected: false,
    component: () => <NotSecureMaintainance />,
  },
  {
    path: "/maintainance-ticket-details/:ticket_zzid/:people_zzid/:action",
    exact: true,
    title: "Maintainance Ticket",
    protected: false,
    component: () => <NotSecureMaintainance />,
  },
  {
    path: "/notice-to-vacate/:leaseId/:id",
    exact: true,
    title: "Notice to Vacate",
    protected: false,
    component: () => <NoticeToVacatePage />,
  },
  {
    path: "/thank-you-notice-to-vacate",
    exact: true,
    title: "Notice to Vacate",
    protected: false,
    component: () => <NoticeToVacateThankYou />,
  },
  {
    path: "/record-user-response-on-vacate-email/:recordId/:option",
    exact: true,
    title: "Notice to Vacate",
    protected: false,
    component: () => <RecordUserResponseOnVacateEmail />,
  },
  {
    path: "/ticket-chart",
    title: "Ticket Chart",
    exact: true,
    protected: false,
    component: () => <MaintainanceTicketChart />,
  },
  {
    path: "/payment-chart",
    title: "payment Chart",
    exact: true,
    protected: false,
    component: () => <PaymentChart />,
  },
  {
    path: "*",
    component: () => <NotFound />,
  },
];

export default routes;
