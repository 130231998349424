import React from "react";
import { withRouter } from "react-router-dom";
import { PageSettings } from "./../../config/page-settings.js";
import { connect } from "react-redux";
import {
  LoginAction,
  ForgotPassword,
  setGlobalzzId,
} from "../../redux/actions/user";
import { Validation } from "../../helpers/validations";
import ChangePassword from "../changePassword";
import { withTranslation } from 'react-i18next';
import ErrorPage from './errorPage';
import logo from '../../assets/img/logo/AniaLogo-full.svg';

class Login extends React.Component {
  static contextType = PageSettings;

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      loading: false,
      remember: false,
      error: { email: "", password: "" },
      forgotPassword: false,
      changePassword: false,
      activeBg: "/assets/img/login-bg/login-bg-17.jpg",
      zzID: null,
      isError: false,
      errorMessage: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.onComplete = this.onComplete.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.switchToLogin = this.switchToLogin.bind(this);
  }

  UNASAFE_componentWillMount() {
    if (this.props.isAuth && !this.props.isResetPassword) {
      this.props.history.push("/dashboard");
    }
  }

  componentDidMount() {
    this.context.handleSetPageSidebar(false);
    this.context.handleSetPageHeader(false);

    let query = new URLSearchParams(
      this.props.history.location.state?.from?.search
    );
    if (query) {
      let zzid = query.get("id");
      let action = query.get("action");
      this.props.setGlobalzzId({ zzid, action });
    }
    const credentials = localStorage.getItem("loginCredentials");
    if (credentials) {
      const { email, password } = JSON.parse(credentials);
      this.setState({ email, password, remember: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.error !== this.props.error) {
      this.setState({ error: prevProps.error });
      return;
    }
    if (this.props.isAuth && !this.props.isResetPassword) {
      
      if(this.props?.location?.state?.from?.state?.redirect_url){
        // this.props.history.push(this.props?.location?.state?.from?.state?.redirect_url);
        const redirectUrl = this.props.location.state.from.state.redirect_url;
        if (redirectUrl) {
          const maintenanceTicketUrl = redirectUrl;
          this.props.history.push(maintenanceTicketUrl);
      } else {
          this.props.history.push(redirectUrl);
      }
        return;
      }
      this.props.history.push("/dashboard");
    }
    if (
      prevProps.isAuth !== this.props.isAuth ||
      prevProps.isResetPassword !== this.props.isResetPassword
    ) {
      if (this.props.isAuth && this.props.isResetPassword) {
        !this.state.changePassword &&
          this.setState({ changePassword: true, zzID: this.props.zzID });
      }
    }
  }

  componentWillUnmount() {
    this.context.handleSetPageSidebar(true);
    this.context.handleSetPageHeader(true);
  }

  reset() {
    this.setState({
      email: "",
      password: "",
    });
  }

  // validator function
  handleValid = ({ email, password }) => {
    if (!Validation.email(email)) {
      this.setState({ error: { email: this.props.t('login.validate.email') } });
      return false;
    } else if (Validation.empty(password) || password.length < 8) {
      this.setState({
        error: {
          password: this.props.t('login.validate.password'),
        },
      });
      return false;
    }
    return true;
  };

  // on complete callback
  onComplete(bool, code, message) {
    if (bool) {
      let payload = { email: this.state.email, password: this.state.password };
      this.state.remember
        ? localStorage.setItem("loginCredentials", JSON.stringify(payload))
        : localStorage.removeItem("loginCredentials");

    } else if (["100", "802", "803", "804", "805", "806", "807", "808", "809", "810", "812"].includes(code)) {
      this.setState({ isError: true, errorMessage: message })
    }
    this.setState({ loading: false });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { email, password } = this.state;
    let payload = { email: email, password: password, client: 1 };
    if (this.handleValid(payload)) {
      this.props.LoginAction(payload, this.onComplete);
      this.setState({ loading: true });
    }
  }

  handleForgotPassword(event) {
    event.preventDefault();
    if (this.state.email) {
      this.props.ForgotPassword({ email: this.state.email }, this.onSuccess);
      this.setState({ loading: true });
    }
  }

  // on success callback runs after successful forgot password
  onSuccess(data) {
    this.setState((state) => {
      this.setState({ loading: false });
    });
    if (data) {
      this.setState({
        forgotPassword: false,
        changePassword: true,
        zzID: data.zzID,
      });
    }
  }

  switchToLogin() {
    this.setState({
      loading: false,
      forgotPassword: false,
      changePassword: false,
      zzID: "",
    });
  }

  handleBack() {
    this.setState({ changePassword: false });
  }

  render() {
    const { error, isError, errorMessage } = this.state;
    const { t } = this.props;
    if (isError) {
      return <ErrorPage errorMessage={errorMessage} />
    }

    return (
      <React.Fragment>
        <div className="login-cover">
          {/* <div
            className="login-cover-image"
            style={{ backgroundImage: "url(" + this.state.activeBg + ")" }}
          ></div>
          <div className="login-cover-bg"></div> */}
        </div>

        <div className="login login-v2">
          <div className="login-header">
            <div className="login-logo">
              <img src={logo} alt='' />
            </div>
            <div className="brand">
              {/* <b>Ania Management</b> */}
              <small>{t('login.welcome')}</small>
            </div>
            {/* <div className="icon">
              <i className="fa fa-lock"></i>
            </div> */}
          </div>
          <div className="login-content">
            {!this.state.changePassword ? (
              <form
                className="margin-bottom-0"
                onSubmit={!this.state.forgotPassword ? this.handleSubmit : this.handleForgotPassword}
              >
                <div className="form-group m-b-20">
                  <input
                    type="email"
                    className={`form-control form-control-lg ${error.email && "is-invalid"}`}
                    placeholder={t('login.email')}
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    required
                    onFocus={(e) => this.setState({ error: { ...this.state.error, email: "" } })
                    }
                  />
                  <h5 className="invalid-feedback">{this.state.error.email}</h5>
                </div>
                {!this.state.forgotPassword && (
                  <>
                    <div className="form-group m-b-20">
                      <input
                        type="password"
                        className={`form-control form-control-lg ${error.password && "is-invalid"}`}
                        placeholder={t('login.password')}
                        value={this.state.password}
                        onChange={(e) => this.setState({ password: e.target.value })}
                        required
                        onFocus={(e) => this.setState({ error: { ...this.state.error, password: "" } })}
                      />
                      <h5 className="invalid-feedback">
                        {this.state.error.password}
                      </h5>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="checkbox checkbox-css m-b-20">
                        <input
                          type="checkbox"
                          id="remember_checkbox"
                          checked={this.state.remember}
                          onChange={(e) => this.setState({ remember: !this.state.remember })}
                        />
                        <label htmlFor="remember_checkbox">{t('login.rememberMe')}</label>
                      </div>
                      <span
                        onClick={(e) => this.setState({ forgotPassword: true })}
                        className="m-t-5 hover-pointer text-darkGrey"
                      >
                        {t('login.forgotPass')}
                      </span>
                    </div>
                  </>
                )}
                {this.state.forgotPassword && (
                  <p
                    className="hover-pointer text-white"
                    onClick={(e) => this.setState({ forgotPassword: false })}
                  >
                    <i className="fas fa-angle-left" /> {t('login.back')}
                  </p>
                )}
                <div className="login-buttons">
                  <button
                    disabled={this.state.loading}
                    type="submit"
                    className="btn btn-success btn-block btn-lg"
                  >
                    {!this.state.loading ? (
                      !this.state.forgotPassword ? (
                        `${t('login.signMeIn')}`
                      ) : (
                        `${t('login.submit')}`
                      )
                    ) : (
                      <i className="fas fa-spinner fa-pulse fa-2x"></i>
                    )}
                  </button>
                </div>
              </form>
            ) : (
              <ChangePassword
                zzID={this.state.zzID}
                switchToLogin={this.switchToLogin}
                handleBack={this.handleBack}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ USER_REDUCER }) => ({
  isAuth: USER_REDUCER && USER_REDUCER.isAuth,
  isResetPassword:
    USER_REDUCER &&
    USER_REDUCER.userData &&
    USER_REDUCER.userData.isNeedsPasswordReset,
  zzID: USER_REDUCER && USER_REDUCER.userData && USER_REDUCER.userData.zzID,
  error: USER_REDUCER && USER_REDUCER.error,
});

export default connect(mapStateToProps, {
  LoginAction,
  ForgotPassword,
  setGlobalzzId,
})(withRouter(withTranslation()(Login)));
