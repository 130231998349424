import { Notify } from "../../helpers/notification";
import Api from "../api/api";

export const getWebQueue = (id) => async (dispatch) => {
  try {
    const result = await Api.get("api/webqueue/get/" + id);
    return result.data.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateWebQueue = (id, body) => async (dispatch) => {
  try {
    const result = await Api.put("api/webqueue/update/" + id, body);
    return result.data.data;
  } catch (error) {
    console.error(error);
    Notify("danger", error.message);
  }
};

export const finishQueue = (id, body) => async (dispatch) => {
  try {
    const result = await Api.post("api/webqueue/finish/" + id, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data.data;
  } catch (error) {
    console.error(error);
    Notify("danger", error.message);
  }
};


export const uploadSignature = (id, body) => async (dispatch) => {
  try {
    const result = await Api.post("api/webqueue/upload-signature/" + id, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data.data;
  } catch (error) {
    console.error(error);
    Notify("danger", error.message);
  }
};



export const deleteQueueById = (id, body) => async (dispatch) => {
  try {
    const result = await Api.delete("api/webqueue/delete/" + id);
    return result.data.data;
  } catch (error) {
    console.error(error);
    Notify("danger", error.message);
  }
};

export const onBoardTenantFromOfficeAction = (body) => async (dispatch) => {
  try {
    const result = await Api.post("api/webqueue/onboard-tenant-from-office", body);
    return result?.data?.data;
  } catch (error) {
    console.error(error);
    Notify("danger", error.message);
  }
};

export const uploadSignatureToFileMakerAction = (body) => async (dispatch) => {
  try {
    const result = await Api.post("api/webqueue/upload-signature-to-filemaker", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data.data;
  } catch (error) {
    console.error(error);
    Notify("danger", error.message);
    throw error
  }
};

export const checkIPAction = () => async (dispatch) => {
  try {
    const result = await Api.get("api/webqueue/check-ip");
    return result.data.data;
  } catch (error) {
    console.error(error);
    Notify("danger", error.message);
    throw error
  }
};